/**
 *
 * Main JS
 *
 */

var EROBG = {

    vars: {},
    init: function () {
        var me = this;

        me.pagerDisabledevents();
        me.windowsResize();
        me.socialMediaEvents();
    },
    pagerDisabledevents: function () {
        var me = this;

        $(document).on('click', '.pagination .disabled a', function (e) {
            e.preventDefault();
        });
    },
    windowsResize: function () {
        var me = this;

        $("img.lazy").lazyload({
            threshold: 40,
            effect: "fadeIn"
        });

        $(window).load(function(){
            $("html,body").trigger("scroll");
        });
    },
    socialMediaEvents: function () {

        $(document).on('click', '.btn-share', function(e){
            e.preventDefault();
            window.open(this.href + encodeURIComponent($('meta[property="og:url"]').attr('content')),'top', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');
        });
    }

}

$(function () {

    EROBG.init();

});